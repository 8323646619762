<template>
  <div class="login">
    <div class="left" @click="signIn" v-if="!islogin">
      <slot name="login"></slot>
    </div>
    <div class="content" @click="register" v-if="!islogin">
      <slot name="register"></slot>
    </div>
    <div class="login-successfully" @click="path1" v-if="islogin">
      <slot name="right"></slot>
    </div>
    <div class="right" @click="path" v-if="islogin">
      <slot name="cart"></slot>
    </div>
  </div>
</template>
<script>
import { user } from "network/login";
import Cookie from "cookie_js";
export default {
  name: "Login",
  data() {
    return {
      user: {},
      islogin: "",
    };
  },
  watch: {
    "$store.state.userinfo": function (newval, oldval) {
      if (newval && newval.id != undefined) {
        this.islogin = true;
      } else {
        this.islogin = false;
      }
    },
  },
  created() {
    if (this.$store.state.token != "") {
      this.getUser();
    }
  },
  methods: {
    //请求会员信息
    getUser() {
      if (this.$store.state.token) {
        user().then((res) => {
          if (res.data.code == 1) {
            this.user = res.data.data.user;
            this.$store.dispatch("setAccount", res.data.data.user);
          } else {
            return;
          }
        });
      }
    },
    register() {
      this.$router.push("/register1");
    },
    signIn() {
      this.$router.push("/signIn");
    },
    path() {
      this.$router.push("/commodities");
    },
    path1() {
      this.$router.push("/member");
    },
  },
};
</script>
<style scoped>
.login {
  margin: 0.25rem 0;
  display: flex;
  font-size: 0.4rem;
  color: #ff7f00;
  flex-direction: row-reverse;
  font-weight: 600;
}

.left {
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid #ff7f00;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
}
.content {
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  text-align: center;
  border: 1px solid #ff7f00;
  margin-right: 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
}
.login-successfully {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 4rem;
  padding: 0.25rem 0.5rem;
  background: #fafafa;
  color: #666666;
  border-radius: 0.2rem;
  margin-right: 0.5rem;
  cursor: pointer;
}
.right {
  background: #ffecd9;
  border-radius: 0.2rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

@media (min-width: 768px) {
  .login {
    float: right;
  }
}
</style>

