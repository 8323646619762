<template>
  <div class="copytight">
    <section>Special T Imports, Inc.</section>
    <p>No Minimums Required, Wholesale to the trade only.</p>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.copytight {
  padding: 1rem 0 0.5rem 0;
  text-align: center;
}
section {
  font-size: 0.4rem;
  color: #666666;
  font-weight: 600;
}
p {
  margin: 0;
  margin-top: 0.25rem;
  font-size: 0.35rem;
  color: #999999;
}
</style>