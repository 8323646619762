<template>
  <div class="top">
    <div class="logo">
      <slot name="logo"></slot>
    </div>
    <div class="text">
      <slot name="text"></slot>
    </div>
    <div class="search d-lg-flex d-none">
      <slot class="input" name="search"></slot>
      <div class="icon">
        <slot name="icon"></slot>
      </div>
    </div>
  </div>
</template>
<style scoped>
.top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}
.logo {
  width: 5rem;
}
.logo img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.search {
  display: flex;
}
.text {
  color: #999999;
  font-size: 1.35rem;
  width: 100%;
  font-family: "txchj";
  text-align: center;
}
.icon {
  background: #ff7f00;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0.2rem 0.2rem 0;
}
</style>
<script>
export default {};
</script>