import axios from 'axios'
import Cookie from 'cookie_js'

export function request(config) {
    const instance = axios.create({
        // baseURL:   'http://specialt.cn',
        baseURL: 'http://www.specialtimports.com',
        // timeout: 4000,
    })


    // instance.interceptors.request.use(
    //     (config)  =>  {
    //         if  (Cookie.get('token'))  { 
    //             config.headers['token']  =  Cookie.get('token')
    //         } 
    //         return  config;
    //     },
    //     (error)  =>  { 
    //         Promise.reject(error)
    //     }
    // ) 


    return instance(config)
}