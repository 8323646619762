<template>
  <div class="nav col-sm-10 col-lg-8 col-xl-8">
    <span
      class="item"
      v-for="(item, index) in nav"
      :key="index"
      @click="path(index)"
      tag="div"
    >
      {{ item.text }}
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      nav: [
        {
          text: "Home",
        },
        {
          text: "About Us",
        },

        {
          text: "Terms",
          //   topUp
        },
        {
          text: "Contact Us",
        },
        // {
        //   text: "Privact",
        // },
      ],
    };
  },
  methods: {
    path(index) {
      if (index == 0) {
        this.$router.push("/home");
      } else if (index == 1) {
        this.$router.push("/about");
      } else if (index == 2) {
        this.$router.push("/customerCarw");
      } else if (index == 3) {
        this.$router.push("/contactUs");
      }
      // else if (index == 4) {
      //   this.$router.push("/privact");
      // }
    },
  },
};
</script>
<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem auto;
}
.item {
  color: #ff7f00;
  font-size: 0.4rem;
  font-weight: bolder;
}
.item:hover {
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .item {
    color: #ff7f00;
    font-size: 0.7rem;
    font-weight: bolder;
  }
}
</style>