import { request } from "./request";


export function user() {
    return request({
        url: '/api/user/index',
    })
};



export function cartAddToOrders(ids) {
    return request({
        url: '/api/orders/cartAddToOrders',
        method: 'post',
        data: {
            ids: ids,
        },

    })
};


export function login(account, password) {
    return request({
        url: '/api/user/login',
        method: 'post',
        data: {
            account,
            password
        },

    })
};