<template>
  <div id="app" class="content">
    <login />
    <top />
    <top-nav />
    <keep-alive
      exclude="Alter,Member,MyOrder,RuturnPage,signIn,Order,specialT,Commodities,product"
    >
      <router-view />
    </keep-alive>
    <bottomNav />
    <copyright />
  </div>
</template>

<style lang="less">
@import "assets/css/base.css";
body {
  background-color: var(--bgc);
}
.content {
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 900px) {
  .content {
    max-width: 95%;
    margin: 0 auto;
  }
}
</style>
<script>
import Top from "./components/content/Top";
import Login from "./components/content/Login";
import TopNav from "./components/content/TopNav";
import BottomNav from "./components/content/BottomNav";
import Copyright from "./components/content/Copyright";
export default {
  components: { Top, Login, TopNav, BottomNav, Copyright },
};
</script>
