import { request } from "./request";

export function getType() {
    return request({
        url: '/api/product/getTreeCategory'
    })
};
export function getCategoryByPage(cid, pageNumber, pageSize, ) {
    return request({
        url: '/api/product/getCategoryByPage',
        params: {
            cid,
            pageNumber,
            pageSize,
        }
    })
}

export function getProductsByPage(title, cid, minPrice, maxPrice, pageNumber, pageSize, ) {
    return request({
        url: '/api/product/getProductsByPage',
        params: {
            title,
            cid,
            minPrice,
            maxPrice,
            pageNumber,
            pageSize,
        }
    })
}

export function getProductDetail(tid, sid) {
    return request({
        url: '/api/product/getProductDetail',
        params: {
            tid,
            sid,
        }
    })
}

export function getProductQuickview(tid, sid) {
    return request({
        url: '/api/product/getProductQuickview',
        params: {
            tid,
            sid,
        }
    })
}
//电子目录   等 单条请求  
export function getSiteinfoByName(pramName) {
    return request({
        url: '/api/siteinfo/getSiteinfoByName',
        params: {
            pramName
        }
    })
}