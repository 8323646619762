import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/font/font.css';
import VueResource from 'vue-resource'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Cookie from 'cookie_js'



Vue.use(ElementUI);
Vue.use(VueResource)
Vue.use(BootstrapVue);

Vue.config.productionTip = false;


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
    /* 判断该路由是否需要登录权限 */
    if (to.matched.some(route => route.meta.requireAuth)) {
        if (Cookie.get('token')) { // 判断用户是否登录，登录之后会存用户相关的信息
            next();
        } else {
            next({
                path: '/signIn',
            })
        }
    } else {
        next() // 确保一定要调用 next()

    }
})