import Vue from 'vue'
import VueRouter from 'vue-router'
import { user } from "network/login";

Vue.use(VueRouter)

const routes = [{
        path: '/',
        component: () =>
            import ('../views/home/Home.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () =>
            import ('../views/home/Home.vue'),
        meta: {
            title: '首页',
        }

    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ('../views/about/About.vue'),
        meta: {
            title: '关于我们',
        }
    },
    {
        path: '/product',
        name: 'Product',
        component: () =>
            import ('../views/product/Product.vue'),
        meta: {
            title: '产品详情',
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: () =>
            import ('../views/register/Register.vue'),
        meta: {
            title: '注册',
        }
    },
    {
        path: '/register1',
        name: 'Register1',
        component: () =>
            import ('../views/register/Register1.vue'),
        meta: {
            title: '注册',
        }
    }, {
        path: '/signIn',
        name: 'SignIn',
        component: () =>
            import ('../views/signIn/SignIn.vue'),
        meta: {
            title: '登录',
        }
    }, {
        path: '/forgetten',
        name: 'Forgetten',
        component: () =>
            import ('../views/forgetten/Forgetten.vue'),
        meta: {
            title: '忘记密码',
        }
    }, {
        path: '/new',
        name: 'New',
        component: () =>
            import ('../views/new/New.vue'),
        meta: {
            title: '新闻',
        }
    }, {
        path: '/rep',
        name: 'Rep',
        component: () =>
            import ('../views/rep/Rep.vue'),
        meta: {
            title: '资讯',
        }
    }, {
        path: '/showrooms',
        name: 'Showrooms',
        component: () =>
            import ('../views/showrooms/Showrooms.vue'),
        meta: {
            title: '展示信息',
        }
    }, {
        path: '/customerCarw',
        name: 'CustomerCarw',
        component: () =>
            import ('../views/customerCarw/CustomerCarw.vue'),
        meta: {
            title: '展示信息',
        }

    }, {
        path: '/privact',
        name: 'Privact',
        component: () =>
            import ('../views/privact/Privact.vue'),
        meta: {
            title: '展示信息',
        }

    }, {
        path: '/contactUs/:email',
        name: 'ContactUs',
        component: () =>
            import ('../views/contactUs/ContactUs.vue'),
        meta: {
            title: '联系我们',
        }

    }, {
        path: '/contactUs',
        name: 'ContactUs',
        component: () =>
            import ('../views/contactUs/ContactUs.vue')
    }, {
        path: '/specialT/:id',
        name: 'SpecialT',
        component: () =>
            import ('../views/specialT/SpecialT.vue'),
        meta: {
            title: '产品中心',
        }

    }, {
        path: '/commodities',
        name: 'Commodities',
        component: () =>
            import ('../views/commodities/Commodities.vue'),
        meta: {
            title: '购物车',
            requireAuth: true
        }

    }, {
        path: '/order',
        name: 'Order',
        component: () =>
            import ('../views/order/Order.vue'),
        meta: {
            title: '订单',
            requireAuth: true
        }
    }, {
        path: '/orderSucceed',
        name: 'OrderSucceed',
        component: () =>
            import ('../views/order/OrderSucceed.vue'),
        meta: {
            title: '订单提交成功',
            requireAuth: true
        }
    },
    {
        path: '/member',
        name: 'member',
        component: () =>
            import ('../views/member/Member.vue'),

        meta: {
            title: '个人中心',
            requireAuth: true
        },
        children: [{
                path: '/',
                name: 'MyOrder',
                component: () =>
                    import ('../views/member/MyOrder.vue')
            }, {
                path: 'myOrder',
                name: 'MyOrder',
                component: () =>
                    import ('../views/member/MyOrder.vue')
            }, {
                path: 'returnOrder',
                name: 'ReturnOrder',
                component: () =>
                    import ('../views/member/ReturnOrder.vue')
            },
            {
                path: 'orderDetail',
                name: 'OrderDetail',
                component: () =>
                    import ('../views/member/OrderDetail.vue')
            },
            {
                path: 'returnDetail',
                name: 'ReturnDetail',
                component: () =>
                    import ('../views/member/ReturnDetail.vue')
            },
            {
                path: 'userInfo',
                name: 'UserInfo',
                component: () =>
                    import ('../views/member/UserInfo.vue')
            },
            {
                path: 'security',
                name: 'Security',
                component: () =>
                    import ('../views/member/Security.vue')
            },
            {
                path: 'ruturnPage',
                name: 'RuturnPage',
                component: () =>
                    import ('../views/member/RuturnPage.vue')
            },

            {
                path: 'orderSucceeds',
                name: 'OrderSucceeds',
                component: () =>
                    import ('../views/member/OrderSucceed.vue')
            },

        ]

    },
    {
        path: '/alter/:type',
        name: 'Alter',
        component: () =>
            import ('../views/alter/Alter.vue'),
        meta: {
            title: '修改信息详情页',
            requireAuth: true
        }
    },
]





const router = new VueRouter({
    routes,
    mode: 'history',
})






const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function(location, onResolve, onReject) {
        if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
        return originalPush.call(this, location).catch(err => err)
    } //解决重复提交路由报错



export default router