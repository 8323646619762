<template>
  <top>
    <img slot="logo"   @click="goHome" src="~assets/images/icon/logo.png"></img>
    <div  slot="text">Special T Imports, Inc.</div>
        <input  slot="search" type="text" placeholder="enter key word or sku" v-model="text">
        <div @click="search"  class="icon" slot="icon">
          <img   src="~assets/images/icon/search.png" alt="">
        </div>
        
  </top>
</template>
<script>
import Top from "../common/top/Top";
export default {
  components: { Top },
  data() {
    return {
      text: "",
      a: "",
    };
  },
  methods: {
    goHome() {
      this.$router.push("/home");
    },
    search() {
      if (this.a == this.text) {
        this.$router.go(0);
      } else {
        this.$router.push({
          path: "/specialT/0",
          query: {
            text: this.text,
          },
        });
      }
      this.a = this.text;
    },
  },
};
</script>
<style scoped>
input {
  border-radius: 0.2rem 0 0 0.2rem;
  border: 1px solid #ff7f00;
  color: #ff7f00;
  font-size: 0.3rem;
  padding: 0 0.5rem;
}
.icon {
  margin: 0;
  padding: 0;
  padding: 0.25rem;
}
</style>