<template>
  <div class="tabnav">
    <!-- <div class="icon d-lg-none d-md-none">
      <img @click="mSet" src="~assets/images/icon/menu.png" alt="" />
    </div>
    <div class="nav-m d-lg-none d-md-none" v-if="m_status">
      <div class="item" slot="nav" v-for="(item, index) in list">
        <span
          :class="curIndex == index ? 'current' : 'button'"
          @click="path(index)"
          >{{ item }}</span
        >
      </div>
    </div> -->

    <div class="nav">
      <div class="item" slot="nav" v-for="(item, index) in list">
        <span
          :class="curIndex == index ? 'current' : 'button'"
          @click="path(index)"
          @mouseenter="enter(index)"
          @mouseleave="leave()"
          >{{ item }}</span
        >
      </div>
    </div>
    <div
      class="pop-ups"
      v-if="curIndex == 0"
      @mouseenter="enter1"
      @mouseleave="leave()"
    >
      <div class="pop-list" v-for="(arr, index) in arr1">
        <div class="menu">
          <span @click="send(arr.id)">{{ arr.name }}</span>
        </div>
        <div class="secondary-menu">
          <p v-for="(item, index) in arr.subitem" @click="send(item.id)">
            {{ item.nickname }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getType } from "network/type";
import Cookie from "cookie_js";
export default {
  components: {},
  data() {
    return {
      arr1: [],
      list: ["Shop", "What’s new", "Find a rep", "Showrooms"],
      curIndex: null,
      m_status: false,
    };
  },
  created() {
    this.getType();
  },
  methods: {
    mSet() {
      this.m_status = !this.m_status;
    },
    enter(index) {
      this.curIndex = index;
      if (index == 0) {
      }
    },
    enter1() {
      this.curIndex = 0;
    },
    leave() {
      this.curIndex = null;
    },
    path(index) {
      this.m_status = !this.m_status;

      if (index == 0) {
        if (Cookie.get("token") != undefined) {
          if ("/specialT/0" == this.$route.path) {
            this.$router.go(0);
          } else {
            // this.$router.path(path)
            this.$router.push("/specialT/" + 0);
          }

          this.$router.push("/specialT/" + 0);
        } else {
          this.$router.push("/signIn");
        }
      } else if (index == 1) {
        this.$router.push("/home");
      } else if (index == 2) {
        this.$router.push("/rep");
      } else if (index == 3) {
        this.$router.push("/showrooms");
      }

      //  else if (index == 2) {
      //   this.$router.push("/new");
      // }
    },
    send(id) {
      if (Cookie.get("token") != undefined) {
        this.$router.push("/specialT/" + id);
      } else {
        this.$router.push("/signIn");
      }
      // this.$router.go(0);
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    getType() {
      getType().then((res) => {
        var data = res.data.data;
        delete data[0];
        var pcategory = [];
        for (var temp in data) {
          if (data[temp].pid == 0) {
            pcategory.push(data[temp]);
            delete data[temp];
          }
        }
        var subitem = [];
        for (var i = 0; i < pcategory.length; i++) {
          for (var temp in data) {
            if (data[temp].pid == pcategory[i].id) {
              subitem.push(data[temp]);
              delete data[temp];
            }
          }
          subitem = subitem.sort(this.compare("weigh"));
          pcategory[i].subitem = subitem;
          subitem = [];
        }
        this.arr1 = pcategory;
        // console.log(this.arr1);
      });
    },
  },
};
</script>
<style scoped>
.icon img {
  width: 1.25rem;
  height: 1.25rem;
}
.tabnav {
  position: relative;
}
.nav {
  position: relative;
  width: 90%;
  margin-left: 10%;
  display: flex;
  font-size: 0.6rem;
  justify-content: space-between;
  color: #ff7f00;
}
.nav-m {
  width: 100%;
  background: #e0e0e0;
  position: absolute;
  z-index: 99999;
  width: 100%;
  font-size: 0.7rem;
  font-weight: 600;
  color: #ff7f00;
}
.nav-m span {
  display: block;
  margin: 0;
  padding: 0.3rem 0.5rem;
}
.button {
  margin-right: 0.5rem;
  padding: 0;

  cursor: pointer;
}
.current {
  margin-right: 0.5rem;
  padding: 0;

  cursor: pointer;
  border-bottom: 1px solid #ff7f00;
}

.nav .item:last-child .point {
  display: none;
}
.pop-ups {
  margin-top: -5px;
  height: 8rem;
  overflow: hidden;
  overflow-y: scroll;
  position: absolute;
  border: 1px solid #ededed;
  display: flex;
  color: #ff7f00;
  font-size: 0.4rem;
  font-weight: 600;
  padding: 0.5rem;
  z-index: 9;
  background: #fff;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
}
p {
  margin: 0.25rem 0 0 0.5rem;
  border-bottom: 1px solid #fff;
}
.pop-list {
  margin-left: 1rem;
}
.pop-list:first-child {
  margin-left: 0.25rem;
}
.menu span {
  border-bottom: 1px solid #fff;
  cursor: pointer;
}
.menu span:hover {
  border-bottom: 1px solid #ff7f00;
}
p:hover {
  cursor: pointer;
  border-bottom: 1px solid #ff7f00;
}

@media screen and (max-width: 768px) {
  .nav {
    font-weight: bolder;
    width: 100%;
    margin-left: 0;
    font-size: 0.7rem;
  }
  .pop-ups {
    display: none;
  }
}
</style>