import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from 'cookie_js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        login: Boolean(Cookie.get('login')) || false,
        userinfo: Cookie.get('userinfo') || '',
        //https: 'http://specialt.cn',
        // https: 'http://xr.37xinxi.cn',
        token: Cookie.get('token') || '',
        uid: Cookie.get('uid') || '',

    },
    getters: {
        getuname: (state) => state.userinfo,
        login: state => state.login,

    },
    mutations: {
        loginStausT(state) {
            state.login = true
            Cookie.set('login', Boolean(JSON.stringify(state.login)))
        },
        loginStausF(state) {
            state.login = false
            Cookie.set('login', Boolean(JSON.stringify(state.login)))

        },
        changeToken(state, user) {
            Cookie.set('token', user['token'], user['expires_in']);
            Cookie.set('uid', user['id']);
        },


        SET_ACCOUNT(state, platform) {
            state.userinfo = platform;
        },

    },
    actions: {
        setAccount({ commit }, platform) {
            commit('SET_ACCOUNT', platform);
        }
    },
    modules: {}
})