<template>
  <login>
    <div slot="login">Sign In</div>
    <div slot="register">Register</div>
    <div class="login-ok" slot="right">
      <span class="span" v-if="$store.state.userinfo.username">{{
        $store.state.userinfo.username
      }}</span>
      <div class="icon-ok-img">
        <img src="~assets/images/icon/loginHover.png" alt="" />
      </div>
    </div>

    <div class="cart" slot="cart">
      <div class="icon1">
        <img src="~assets/images/icon/cart.png" alt="" />
      </div>
      <span class="span">cart</span>
      <!-- <span v-if="$store.state.userinfo.cart_total"
        >${{ $store.state.userinfo.cart_total }}</span
      > -->
    </div>
  </login>
</template>
<script>
import Login from "../common/login/Login";

export default {
  components: { Login },
  data() {
    return {
      user: {},
    };
  },
};
</script>
<style scoped>
.icon {
  width: 30px;
}
.icon1 img {
  width: 100%;
}
.icon1 {
  width: 30px;
  margin-right: 0.25rem;
}
.icon img {
  width: 100%;
}
.icon-ok-img img {
  width: 100%;
}
.span {
  margin-right: 0.25rem;
}
.login-ok {
  display: flex;
}
.right {
  display: flex;
}
.login-ok span {
  width: 1.75rem;
  display: block; /*内联对象需加*/
  word-break: keep-all; /* 不换行 */
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 内容超出宽度时隐藏超出部分的内容 */
  text-overflow: ellipsis; /* 当对象内文本溢出时显示省略标记(...) ；需与overflow:hidden;一起使用。*/
}
.cart {
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 0.2rem;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
}
</style>